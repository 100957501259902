
<template>
  <div class="edit_insurance">
    <div class="title">编辑CA证书</div>
    <!-- 用户名称 证件类型编码 用户证件号 密码(8-16位) 有效期年限(1-10年) 证书类型 经办人姓名 经办人手机号 -->
    <!-- username idType idNo pwd years caType operatorName operatorPhone -->
    <el-form :model="form" ref="form" label-width="10rem" class="ruleForm" :rules="rules">
      <el-form-item label="企业名称" prop="companyId">
       <el-select v-model="form.companyId" placeholder="请选择" clearable  class="filter-item">
            <el-option v-for="item in companyList" :key="item.id" :label="item.companyName" :value="item.id"/>
        </el-select>
      </el-form-item>
      <el-form-item label="证件类型编码" prop="idType"> 
        <!-- 居民身份证：填入“0” 统一社会信用代码：填入“N” -->
        <!-- <el-input v-model="form.idType" placeholder="请输入证件类型编码"></el-input> -->
        <el-select v-model="form.idType" placeholder="请选择" clearable  class="filter-item">
            <el-option v-for="item in idTypeOptions" :key="item.value" :label="item.key" :value="item.value"/>
        </el-select>
      </el-form-item>
      <el-form-item label="用户证件号" prop="idNo">
        <el-input v-model="form.idNo" placeholder="请输入用户证件号" maxlength="20" ></el-input>
      </el-form-item>
      <el-form-item label="密码(8-16位)" prop="pwd">
        <el-input v-model="form.pwd" placeholder="请输入密码" ></el-input>
      </el-form-item>
      <el-form-item label="有效期年限(1-10年)" prop="years">
        <el-input-number v-model="form.years" controls-position="right" :min="1" :max="10" class="filter-item"></el-input-number>
      </el-form-item>
      <el-form-item label="证书类型" prop="caType">
        <el-select v-model="form.caType" placeholder="请选择" clearable  class="filter-item">
            <el-option v-for="item in certificateTypeOptions" :key="item.value" :label="item.key" :value="item.value"/>
        </el-select>
      </el-form-item>
      <el-form-item label="经办人姓名" prop="operatorName">
        <el-input v-model="form.operatorName" placeholder="请输入经办人姓名" maxlength="20"></el-input>
      </el-form-item>
      <el-form-item label="经办人手机号" prop="operatorPhone">
        <el-input v-model="form.operatorPhone"  placeholder="请输入经办人手机号"></el-input>
      </el-form-item>
      

      <el-form-item class="btn">
        <el-button type="primary" @click="submitForm()" :loading="isLoading">保存</el-button>
        <el-button @click="back" class="cancel_btn">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  data() {
     const checkPhone = (rule, value, callback) => {
      if(value) {
        var pass=false;
        const mobile = /^(((\+86)|(\+886)|(\+852)|(\+853))\s+)?((13[0-9])|(14[5,7])|(15[0-3,5-9])|(17[0,3,5-8])|(18[0-9])|166|198|199|190)\d{8}$/;
        if(mobile.test(value)){
          pass=true;
        }
        if (!pass) {
          return callback(
              new Error("请输入正确的手机号码")
          )
        }
      }
      callback()
    }
     const checkTax = (rule, value, callback) => {
      if (value == "") {
        return callback(new Error("请输入纳税人识别号"));
      } else if (
          value.length == 15 ||
          value.length == 16 ||
          value.length == 17 ||
          value.length == 18 ||
          value.length == 19 ||
          value.length == 20
      ) {
        const pattern = /^[A-Z0-9]+$/;
        if (!pattern.test(value)) {
          return callback(new Error("纳税人识别号只能输入数字或大写英文字母"));
        }
        callback();
      } else {
        return callback(new Error("请检查纳税人识别号"));
      }
    };
    return {
      className: "",
      form: {
        // companyId idType idNo pwd years caType operatorName operatorPhone
        companyId: '', //用户名称
        idType: '', //证件类型编码
        idNo: '', //用户证件号
        pwd: '', //密码(8-16位)
        years: '', //有效期年限(1-10年)
        caType: '', //证书类型
        operatorName: '', //经办人姓名
        operatorPhone: '',  //经办人手机号
      },
      rules: {
        companyId: [{ required: true, message: '请输入企业名称', trigger: 'blur' },],
        idType: [{ required: true, message: '请输入证件类型编码', trigger: 'blur' },],
        idNo: [{ required: true, message: '请输入用户证件号', trigger: 'blur' },],
        pwd: [{ required: true, message: '请输入密码', trigger: 'blur' },{ min: 8, max: 16, message: '密码长度至少为8位且不超过16位' },],
        years: [{ required: true, message: '请输入有效期年限', trigger: 'blur' },],
        caType: [{ required: true, message: '请选择证书类型', trigger: 'blur' },],
        operatorName: [{ required: true, message: '请输入经办人姓名', trigger: 'blur' },],
        operatorPhone: [{ required: true, message: "请输入手机号码",trigger: 'blur' },{validator: checkPhone, trigger: "blur" }],
      },
      //证书类型 证书类型，1-RSA1024；2-RSA2048；3-SM2
      // certificateTypeOptions:[{'key': 'RSA1024','value': '1'},{'key': 'RSA2048','value': '2'},{'key': 'SM2','value': '3'}],
      certificateTypeOptions:[{'key': 'RSA1024','value': '1'}],
      //证件类型编码 居民身份证：填入“0” 统一社会信用代码：填入“N”
      idTypeOptions:[{'key': '居民身份证','value': '0'},{'key': '统一社会信用代码','value': 'N'},],
      isLoading: false,
      companyList:[],
    }
  },

  methods: {
  //CA证书接入企业查询
 getJoinCompany(){  //初始化内容
 		this.isLoading = true;
    let data = {userId: this.getToken('userId'),};
    this.$store.dispatch("joinCompany", data).then(res => {
            this.isLoading = false;
           if (res.success) {
             this.companyList = res.data;
              // console.log('获取企业表',res.data)
            } else {
              einvAlert.error("提示",res.msg)
            }
            }).catch(err => {
              this.isLoading = false;
          })
  },
 //CA证书单个查询
 init(){  //初始化内容
 		this.isLoading = true;
    let data = {companyId: this.getToken('companyId'),id:this.$route.query.id, };
    console.log(data,'datadatadatadata')
    this.$store.dispatch("findCAId", data).then(res => {
            this.isLoading = false;
           if (res.success) {
              this.form = res.data;
              this.form.pwd = res.data.caPwd;
            } else {
              einvAlert.error("提示",res.msg)
            }
            }).catch(err => {
              this.isLoading = false;
          })
  },
    // 保存
    submitForm() {
      this.$refs.form.validate((valid) => {
        if (valid) {
           let data = {...this.form,userId: this.getToken('userId'),  };
           this.$store.dispatch("editCA", data).then(res => {
            this.isLoading = false;
            if (res.success) {
              einvAlert.success("提示",'处理成功')
              this.$router.push('/certificate/CA')
            } else {
              einvAlert.error("提示",res.msg)
            }
            }).catch(err => {
              this.isLoading = false;
            // einvAlert.error("提示",err)
            })
        }
      });
    },
    back() {
      this.$router.push('/certificate/CA');
    },

   
  },

  mounted() {
    // this.accountCapitalName();
    this.init();
    this.getJoinCompany();
  }

}
</script>
<style lang="less" scoped>
.edit_insurance {
  height: calc(100% - 60px);
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  .title {
    text-align: center;
    font-weight: 700;
    color: #333333;
    font-size: 18px;
    padding: 18px 0;
    width: calc(100% - 40px);
    border-bottom: 1px solid #dcdcdc;
  }
  .ruleForm {
    margin: 40px auto;
    .el-form-item__content {
      .el-input__inner {
        width: 420px;
      }
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none !important;
      }
      .el-input.is-disabled {
        .el-input__inner {
          background-color: #f6f6f6;
        }
      }
    }

    .btn {
      text-align: center;
      .el-button {
        width: 100px;
      }
      .cancel_btn {
        margin-left: 20px;
      }
    }
  }
}
.el-form-item__content {
  text-align: center !important;
}
.filter-item{
  width: 100%;
}
/deep/ .el-input-number .el-input__inner {
  text-align: left; //位置居左
}

</style>